.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-header {
    text-align: center;
    font-size: 1.7rem;
    margin-top: 8rem;
    margin-bottom: 1.2rem;
}

.projects-disclaimer {
    margin: 1rem 2rem;
}
 
.project-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 2rem;
    }

    div {
        margin: 1rem;
    }
}

.project-group>p:hover {
    cursor: pointer;
}

.hidden-project-group {
    overflow: hidden;
    transition: border 0.5s ease-out, max-height 0.5s ease-out;
    max-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border: 0px solid black;
    border-radius: 1rem;
    width: 80%;

   
    .project-title {
        font-size: 1.5rem;
        margin-bottom: 0.2rem;
    }

    .project-desc {
        font-size: 1.2rem;
    }
}

.project-group-display {
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    max-height: 60rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    border-radius: 1rem;
    width: 80%;
    
    .project-title {
        font-size: 1.5rem;
        margin-bottom: 0.2rem;
    }

    .project-desc {
        font-size: 1.2rem;
        height: fit-content;
    }
}

.project-info {
    margin: 1rem 2rem;
    font-size: 1.2rem;
}

@media (min-width: 1200px) {
    .projects-header {
        margin-top: 16rem;
    }
}