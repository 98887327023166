@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  color: #fcfcfc;
  scroll-behavior: smooth;
}

body {
  background-color: #1a1919;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title{
  grid-area: title;
  gap: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 11rem;
  margin-bottom: 2rem
}

.title-greeting {
  display: inline-block;
  margin-top: 0.2rem;
  font-size: 4rem;
  width: 70%;
}

.title-greeting-fade-in {
  animation: title-fade-in 2s;
}

.subtitle {
  font-size: 1.5rem;
  padding: 0 2rem;
  text-align: center;
}

.subtitle-fade-in {
  animation: subtitle-fade-in 3s;
}

@keyframes title-fade-in {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

@keyframes subtitle-fade-in {
  0%    { opacity: 0; }
  20%    { opacity: 0; }
  40%    { opacity: 0; }
  100%  { opacity: 1; }
}

.projects-invitation {
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 60%;
  font-size: 1.4rem;
}

.home-projects-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  gap: 1.5rem;
}

.home-projects-footer-fade-in {
  animation: skills-fade-in 12s;
}

footer {
  grid-area: footer;
  margin-top: 3em;
  margin-bottom: 2em;
  text-align: center;
}

.footer-fade-in {
  animation: skills-fade-in 12s;
}

@media (min-width: 700px) {

  .title-greeting {
    text-align: center;
  }

  .subtitle {
    padding: 0 5rem;
    text-align: center;
  }

  @keyframes homeskill-left-center {
    0% { transform: translateX(-110em); }
    1% { transform: translateX(-110em); }
    50% { transform: translateX(-110em); }
    55% { transform: translatex(0); } 
  }

  .projects-disclaimer {
    padding: 0 6rem;
    text-align: center;
  }

  .project-info {
    padding: 0 5rem;
    text-align: center;
  }
}

@media (min-width: 1200px) {

  @keyframes subtitle-left-center {
    0% { transform: translateX(-50em); opacity: 0; }
    70% { transform: translateX(-50em); opacity: 0; }
    100% { transform: translatex(0em); opacity:1; }
  }

  .title {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'greeting greeting greeting'
                          '. subtitle subtitle';
    height: fit-content;
    margin-top: 15rem;
    margin-bottom: -2rem;

    .title-greeting {
      font-size: 7rem;
      width: fit-content;
      margin: 2rem 0;
      grid-area: greeting;
      width: 60%;
    }
    .subtitle {
      animation-timing-function: linear;
      grid-area: subtitle;
      font-size: 2rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .home-projects-footer {
    margin-top: 4rem;
  }

  .projects-invitation {
    text-align: left;
  }

  form {

    label {
      font-size: 1.2rem
    }

    input {
      font-size: 1.5rem;
    }

    textarea {
      font-size: 1.8rem;
    }
  }
}