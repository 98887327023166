nav {
  display: flex;
  grid-area: navbar;
  background-color: #000000;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #0f0f0f;
  color: #fcfcfc;
  z-index: 1;

  .navbar-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    * {
      width: 30%
    }
  }

  .navbar-img {
    display: flex;
    gap: 3rem;
  }
  
  a {
    background-color: transparent;
    text-decoration: none;
    border-style: none;
    font-size: 1.25rem;
    color: #fcfcfc;
    text-align: center;
    img {
      cursor: pointer;
      height: 3rem;
    }
  }
  button {
    background-color: transparent;
    border: none;
  }
}
  
.navbar-links-fade {
  animation: subtitle-fade-in 6s;
}


@media (min-width: 1200px) {

  nav {
    padding-bottom: 2rem;
    margin-bottom: 80rem !important;
  }
  
  .navbar-links {
    justify-content: center !important;    
    a {
      font-size: 3rem;
    }
  }

  .navbar-img {
    margin-top: 1rem;
    gap: 9rem !important;

    img {
      width: 4rem;
      height: 4rem;
    }
  }
}

.img-bounce-1 {
  animation: navbar-img-bounce 5s;
}

.img-bounce-2 {
  animation: navbar-img-bounce 6s;
}

.img-bounce-3 {
  animation: navbar-img-bounce 7s;
}

@keyframes navbar-img-bounce {
  0% { transform: translateY(-20em); }
  1% { transform: translateY(-20em); }
  50% {transform: translateY(-20em); }
  60%, 80%, 100% { transform: translateY(0); } 
}