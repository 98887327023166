
.contact-form {
    padding: 1rem;
    margin-top: 1rem;
  
    h3 {
      padding: 0 1rem;
      margin-bottom: 2rem !important;
      text-align: center;
    }
  
    form {
      display: flex;
      flex-direction: column;
      padding: 0 2.5rem;
      margin-top: 0.5rem;
      gap: 0.4rem;
  
      label {
        padding: 0.2rem 0 !important;
      }
  
      input {
        width: 70%;
        border: 1px solid black;
        border-radius: 1em;
        padding: 1rem;
        font-size: 1.2rem;
        color: #000000;
      }
      
      textarea {
        border: 1px solid black;
        border-radius: 1em;
        height: 10rem;
        margin-bottom: 1rem;
        padding: 1rem;
        font-size: 1.2rem;
        color: #000000;
      }
  
      input[type='submit'] {
        width: 30%;
      }
  
      input[type='submit']:hover {
        width: 30%;
        background-color: rgb(221, 221, 221);
      }
  
      input[type='submit']:active {
        width: 30%;
        background-color: rgb(221, 221, 221);
      }
    }
  }
  
  .emailResult {
    margin-top: 1.5rem;
    font-size: 1.2rem;
  }
  