@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.about-me {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-photo {
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  margin-top: 12rem;
}

.about-header {
    font-size: 2.5rem;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.about-desc {
    margin: 0 2rem;
    margin-top: 0.5em;
    font-size: 1.1rem;
}

.about-skills {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  div {
    width: 70%;
  }
}

ul {
  width: 100;

  li {
    font-size: 1.2rem;
  }
}

@media (min-width: 700px) {
  .about-header {
    font-size: 6rem;
  }

  .about-desc {
    margin-top: 2rem;
    padding: 0 10rem;
    font-size: 1.4rem;
    width: 60vw;
  }

  .profile-photo {
    width: 15rem;
    height: 15rem;
    margin-top: 14rem;
  }
}